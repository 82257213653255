/*global window */

(function() {

$('#carousel-example-generic').on('slid.bs.carousel', function(e) {
    var _marker = $('#carousel-example-generic .active').data('coordinate');

    reloadMarkers(_marker);

    $('#carousel-example-generic .active .twentytwenty-container').twentytwenty();
  });

var map;
var markers = []; // Create a marker array to hold your markers

function setMarkers(_marker) {

  var _image = {
    url: decodeURIComponent(_marker.image),
    // This marker is 20 pixels wide by 32 pixels high.
    size: new google.maps.Size(200, 320),
    // The origin for this image is (0, 0).
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new google.maps.Point(0, 32)
  };

  var myLatLng = new google.maps.LatLng(_marker.lat, _marker.lng);
  var marker = new google.maps.Marker({
    position: myLatLng,
    map: map,
    animation: google.maps.Animation.DROP,
    icon: _image
  });

  var _url = _marker.url;
  marker.addListener('click', function() {
    document.location = _url;
  });
  // Push marker to markers array
  markers.push(marker);

  map.setCenter(myLatLng);
  map.setZoom(15);

  setTimeout(function() {google.maps.event.trigger(map, 'resize');}, 200);
}

function reloadMarkers(_marker) {
  // Loop through markers and set map to null for each
  for (var i = 0; i < markers.length; i++) {
    markers[i].setMap(null);
  }
  // Reset the markers array
  markers = [];
  // Call set markers to re-add markers
  setMarkers(_marker);
}

function initialize() {
  var _marker = $('#carousel-example-generic .active').data('coordinate');

  var mapOptions = {
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      zoom: 15,
      center: new google.maps.LatLng(_marker.lat, _marker.lng),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  setMarkers(_marker);


  //reloadMarkers(_marker);

  // Bind event listener on button to reload markers
  //document.getElementById('reloadMarkers').addEventListener('click', reloadMarkers);
}

var mapUltimiLavori;
var markersUltimiLavori = []; // Create a marker array to hold your markers

function setMarkersUltimiLavori(_markersUltimiLavori) {
  var _bounds = new google.maps.LatLngBounds();

  for (var i = 0; i < _markersUltimiLavori.length; i++) {
    var _image = {
        url: decodeURIComponent(_markersUltimiLavori[i].image),
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(200, 320),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(0, 32)
      };

    //var beach = locations[i];
    var myLatLng = new google.maps.LatLng(_markersUltimiLavori[i].lat, _markersUltimiLavori[i].lng);
    var markerUL = new google.maps.Marker({
        position: myLatLng,
        map: mapUltimiLavori,
        animation: google.maps.Animation.DROP,
        icon: _image
      });

    (function() {
      var _url = _markersUltimiLavori[i].url;
      google.maps.event.addListener(markerUL, 'click', function() {
        document.location = _url;
      });
    }());
    // Push marker to markers array
    markersUltimiLavori.push(markerUL);

    _bounds.extend(myLatLng);
  }

  mapUltimiLavori.fitBounds(_bounds);
}
function initializeUltimiLavori() {
  var _markersUltimiLavori = $('#mappa-ultimi-lavori').data('markers') || {};

  var mapOptions = {
      scrollwheel: false,
      navigationControl: false,
      mapTypeControl: false,
      zoom: 5,
      center: new google.maps.LatLng(42,12),
      mapTypeId: google.maps.MapTypeId.ROADMAP
    };
  mapUltimiLavori = new google.maps.Map(document.getElementById('mappa-ultimi-lavori'), mapOptions);
  setMarkersUltimiLavori(_markersUltimiLavori);

  $('.contenuto_lavoro_primadopo').height($('.contenuto_lavoro_primadopo').height());
}

    

$(window).load(function() {
    $('.twentytwenty-container').twentytwenty();
    initialize();

    initializeUltimiLavori();


  });

  var sistema = function(){
    $('.contenuto_lavoro_primadopo').height($('#carousel-example-generic .active .twentytwenty-container').height());
  }

  $(window).on('resize', function(){
    sistema();
  })


}());
